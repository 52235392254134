




















































































export default {
  name : 'SpiritualProgram',
}
